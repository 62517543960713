@tailwind base;
@tailwind components;
@tailwind utilities;

#bg {
  position: fixed;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
}

@media (min-width: 768px) {
  #bg {
    position: fixed;
    top: -50%;
    left: -50%;
    width: 200% !important;
    height: 100rem !important;
  }
}


#bg img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  min-width: 50%;
  min-height: 50%;
}
#page-wrap { position: relative; z-index: 2;   }
		p { font: 15px/2 Georgia, Serif; text-indent: 40px; }
